import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import ReactPixel from 'react-facebook-pixel';

import { pollFinished } from 'services/polls';
import BUTTON_ENUMS from '../../shared/engage-flow-enums';
import i18n from '../../i18n/helper';
import { handleScroll } from '../question/QuestionUtils/QuestionFormUtils';
import PersonHappy from '../../assets-v2/images/illustration/thank-you.png';
import DetailsButton from '../button/DetailsButton';
import SvgIcon from '../SvgIcon';
import { SECONDS_PER_QUESTION } from '../../constants';

const { DEFAULT_BUTTON } = BUTTON_ENUMS().EngageEndOfPollFlowButtonType;
const { ADDON_BUTTON } = BUTTON_ENUMS().EngageEndOfPollFlowButtonType;
const { ADDON_SECOND_BUTTON } = BUTTON_ENUMS().EngageEndOfPollFlowButtonType;

function EndOfPollAnonymous(props) {
  const { pollId, polls: pollsOld, customizations: customizationsOld, nextPollAction, closePoll, slugId } = props;
  const { pollsNew, customizationsNew } = props;

  // Backward compatibility handling
  // we can have polls and customizations brought in from redux (old way - survey as a popup)
  // or directly via props (new way - standalone survey component)
  const polls = pollsNew || pollsOld;
  const customizations = customizationsNew || customizationsOld;

  const { endOfPollButtons } = customizations.endOfPollFlows;

  const [isScrollingClass, setScrollingClass] = useState('');

  useEffect(() => {
    pollFinished(slugId, pollId);
  }, [pollId, slugId]);

  const nextPoll = polls.polls.find((poll) => !poll.isFinished && pollId !== poll._id);
  const nextPollDuration = () => {
    const pollDuration = (nextPoll.questionCount * SECONDS_PER_QUESTION) / 60;
    return pollDuration < 1
      ? i18n.t('pollsScreen.underAMinute')
      : i18n.t('pollsScreen.underMinutes', { time: Math.ceil(pollDuration) });
  };
  // const nextPollDuration = nextPoll ? Math.round((nextPoll.questionCount * SECONDS_PER_QUESTION) / 60) : 0;

  // Customization OVERWRITES/REPLACES standard default goto HOME button
  const customDefaultButton = endOfPollButtons.find((button) => button.buttonTypeIdentifier === DEFAULT_BUTTON);
  const customAddonButton = endOfPollButtons.find((button) => button.buttonTypeIdentifier === ADDON_BUTTON);
  const secondCustomAddonButton = endOfPollButtons.find(
    (button) => button.buttonTypeIdentifier === ADDON_SECOND_BUTTON,
  );

  /**
   * Configuring buttons
   * IMPORTANT !!!
   * The order of button insertion defines the order of button display
   */
  function getButtonConfiguration(_button, _closePoll) {
    return {
      linkTo: _button.endOfPollButtonActionUrl,
      title: _button.endOfPollButtonTitle,
      subtitle: _button.endOfPollButtonSubTitle,
      openInNewWindow: _button.openInNewWindow,
      closePoll: _closePoll,
    };
  }

  const nextPollButton = nextPoll
    ? {
        linkTo: `/surveys/${nextPoll._id}`,
        isPrimary: !!nextPoll,
        title: i18n.t('gamification.endOfPoll.nextPollButton.title'),
        subtitle: `${nextPoll.name} — ${nextPollDuration()}`,
        action: () => nextPollAction(nextPoll._id),
      }
    : {};

  const endOfPollButtonStandard = {
    linkTo: '/',
    title: i18n.t('gamification.endOfPoll.aboutButton.title'),
    subtitle: i18n.t('gamification.endOfPoll.aboutButton.subtitle'),
    closePoll,
  };

  function getButtons() {
    const buttons = [];

    // Next poll button
    if (!customizations.hideNextPollButton && nextPoll) {
      buttons.push(nextPollButton);
    }

    // HOME button
    // Show default if no customization present
    if (!customDefaultButton) {
      buttons.push(endOfPollButtonStandard);
    } else {
      buttons.push(getButtonConfiguration(customDefaultButton, closePoll));
    }

    // Add first custom button
    // 1) if defined on Backend
    // 2) we show max 3 buttons, so add only if 3 buttons NOT already defined
    if (customAddonButton && buttons.length < 3) {
      buttons.push(getButtonConfiguration(customAddonButton));
    }

    // Add second custom button
    // 1) if defined on Backend
    // 2) we show max 3 buttons, so add only if 3 buttons NOT already defined
    if (secondCustomAddonButton && buttons.length < 3) {
      buttons.push(getButtonConfiguration(secondCustomAddonButton));
    }

    return buttons;
  }

  const buttons = getButtons();

  // console.log(buttons);

  return (
    <React.Fragment>
      <div className='c-modal-poll__header'>
        {!customizations.hideClosePollButton && (
          <button className='c-button c-modal-poll__close' onClick={closePoll}>
            <span className='c-button__label'>Close</span>
            <SvgIcon icon='times' />
          </button>
        )}
      </div>

      <div className='c-modal-poll__content'>
        <div className={`c-question c-question--wide ${isScrollingClass}`}>
          <div className='c-question__header' />

          <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
            <div className='c-poll-finished'>
              <figure className='c-poll-finished__illustration'>
                <img src={PersonHappy} alt='' />
              </figure>

              <h2 className='c-poll-finished__title'>{i18n.t('gamification.endOfPoll.title')}</h2>

              <div className='c-poll-finished__options'>
                {buttons.map((button, idx) => (
                  <DetailsButton key={idx} {...button} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  charities: state.charities.data,
  polls: state.polls,
  customizations: state.customizations,
});

export default connect(mapStateToProps)(EndOfPollAnonymous);
