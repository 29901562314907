import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Img, SQUARE } from '../images/Img';
import SvgIcon from '../SvgIcon';
import i18n from '../../i18n/helper';

const CharityItem = (props) => {
  const [isClosed, setIsClosed] = useState(true);
  const { icon, title, description, id, setCharityId, charityId } = props;

  useEffect(() => {
    if (charityId === id) {
      setIsClosed(false);
    }
  }, [charityId, id]);

  return (
    <React.Fragment>
      <div className={`c-choice-charity ${isClosed ? 'is-closed' : 'is-opened'}`}>
        <input
          type='checkbox'
          id={`charity-${id}`}
          name='pick-charity'
          checked={charityId === id}
          onChange={() => setCharityId(id)}
        />
        <label htmlFor={`charity-${id}`}>
          {icon && (
            <span className='c-choice-charity__media'>
              <Img imageType={SQUARE} imageId={icon} alt='member' />
            </span>
          )}
          <span className='c-choice-charity__title'>{title}</span>
          <span className='c-choice-charity__description'>{description}</span>
        </label>
        <button className='c-button c-choice-charity__toggle' onClick={() => setIsClosed(!isClosed)}>
          <span className='c-button__label'>{i18n.t('gamification.details')}</span>
          <SvgIcon icon='angle-down' />
        </button>
      </div>
    </React.Fragment>
  );
};

CharityItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  setCharityId: PropTypes.func.isRequired,
  charityId: PropTypes.string.isRequired,
};

export default CharityItem;
