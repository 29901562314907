import React from 'react';
import PropTypes from 'prop-types';
import { SQUARE } from '../images/Img';
import SelectImage from './SelectImage';

const Image = (props) => {
  // ####
  // Occasionally (ie. ChoiceOther) we send a fixed image and NOT decoding from image ID
  // So isFixedImageURL overrides the image ID decoding standard
  // ####
  const {
    isFixedImageURL,
    idx,
    resource,
    onImageClick,
    imageText,
    communityAnswerAmount,
    imageType = SQUARE,
    isResult,
    isSelected,
    isDisabled,
    questionId,
    showCoin,
  } = props;

  return (
    <div
      className={`c-choice-image c-choice-image--bar ${isResult ? ' is-result is-disabled ' : ''} ${
        isSelected ? ' is-highlight ' : ''
      }`}
    >
      <input
        type='checkbox'
        id={`choice-name-${idx}`}
        name={`choice-name-radio-${questionId}`}
        checked={isSelected}
        disabled={isDisabled}
        onClick={onImageClick}
      />
      <label htmlFor={`choice-name-${idx}`}>
        <SelectImage
          isFixedImgURL={isFixedImageURL}
          resource={resource}
          choiceName={imageText}
          communityAnswer={communityAnswerAmount}
          imageType={`${imageType}`}
          showCoin={showCoin}
        />
        <span className='c-choice-image__title'>{imageText}</span>
      </label>
    </div>
  );
};

Image.propTypes = {
  isFixedImageURL: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  imageText: PropTypes.string,
  communityAnswerAmount: PropTypes.number,
  imageType: PropTypes.string,
  isResult: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  questionId: PropTypes.string.isRequired,
  showCoin: PropTypes.bool,
};

export default Image;
