import { makeQualifyingQuestion } from 'containers/questions/QuestionConstants';
import { getCombinedQuestions, getTotalNrQuestions } from 'utils/pollQuestionUtils';

const initialState = {
  showPoll: false,
};

export default (state = initialState, action) => {
  // API returns e.g. 'multiple-image other choice' as questionType for segmentation question
  // question renderer in QuestionSwitcher expects different questionTypes for segmentation and normal questions
  // thus, we change segmentation questionType to match the expected value (e.g. QQ_106_MULTI_IMAGE) and not
  // the value received from the server

  switch (action.type) {
    case 'SUCCESS_RETRIEVE_POLL': {
      // this call returns
      // https://github.com/sdesregistry/IH-Engage/blob/fix/conditional-usecases/src/utils/hooks/useConditionalQuestion/conditional-usecases.md#new-questions-schema
      const qualifyingQuestion = action.data.data.segmentationQuestion && {
        ...action.data.data.segmentationQuestion,
        question: {
          ...action.data.data.segmentationQuestion?.question,
          questionType: makeQualifyingQuestion(action.data.data.segmentationQuestion?.question.questionType),
        },
      };
      return {
        ...state,
        ...action.data.data,
        questions: getCombinedQuestions(
          action.data.data.questions,
          [qualifyingQuestion, action.data.data.demographicQuestion, action.data.data.personalData],
          action.data.data.isConditionalPoll,
        ),
        totalQuestions: getTotalNrQuestions(action.data.data.questions),
      };
    }

    default:
      return state;
  }
};
