import { useState, useRef, useEffect } from 'react';

import { useGetNextQuestion } from 'utils/hooks/useGetNextQuestion/useGetNextQuestion';
import { pollQuestionSkip, pollQuestionSeen, submitCharityDonation } from 'services/polls';
import type { CommonQuestionProps } from 'components/organisms/questions/types';
import type { Answer } from 'components/organisms/question/types';
import QuestionHeader from 'components/question/QuestionHeader';
import PollProgress from 'components/question/PollProgress';
import {
  AT_1007_CHARITY,
  AT_1014_END_OF_POLL,
  INCLUDE_DEMOGRAPHIC,
  INCLUDE_FACTS,
  INCLUDE_QQ,
  EXCLUDE_FACTS,
  isQualifyingQuestion,
  isQuestion,
  isDemographicQuestion,
} from 'containers/questions/QuestionConstants';
import EndOfPollCharity from 'components/gamefication/EndOfPollCharity';
import EndOfPollAnonymous from 'components/gamefication/EndOfPollAnonymous';
import QuestionHelper from 'containers/questions/QuestionHelper';
import { SUBMIT_DELAY } from '../../constants';

import SurveyUtils from './SurveyUtils';
import { SurveyFormProps } from './SurveyTypes';

export default function Survey(props: SurveyFormProps): JSX.Element {
  const { apiData, match, slugId, location, projectConfiguration, initialQuestion } = props;
  const { poll, surveyList, charities } = apiData;
  const id = match.params.id;

  const [annotateEdit, setAnnotateEdit] = useState('');
  const [isMediaZoomed, setIsMediaZoomed] = useState(false);
  const [showCoins, setShowCoins] = useState(false);
  const [hideSkipDemographic, setHideSkipDemographic] = useState(false);
  const [hideSkipAfterAnswer, setHideSkipAfterAnswer] = useState(false);

  const questions = SurveyUtils.getCombinedQuestions(poll);
  const answers = useRef<Answer[]>([]);
  const [currentQuestionIndex, currentForm, getNextQuestion] = useGetNextQuestion(
    questions,
    poll?.lastQuestionAnswered || null,
    poll?.isConditionalPoll || false,
    initialQuestion,
    undefined, // initialFormType,
    SurveyUtils.getActiveCharities(charities).length > 0,
    poll?.isCharityChosen || false,
    id,
    answers,
  );
  const questionId = questions[currentQuestionIndex] ? questions[currentQuestionIndex]._id : 0;

  /**
   *
   */
  async function onCharityClick(charityId: string) {
    const data = { charityId };
    await submitCharityDonation(slugId, poll?._id, charityId, data);
    getNextQuestion();
  }

  /**
   *
   */
  function nextPoll(nextSurveryId: string) {
    SurveyUtils.gotoNextSurvey(nextSurveryId, location);
  }

  /**
   *
   */
  function closePoll() {
    SurveyUtils.closePoll(projectConfiguration?.customizations, location);
  }

  /**
   *
   */
  function onEndQuestion() {
    setHideSkipAfterAnswer(false);
    setAnnotateEdit('');
    getNextQuestion();
  }

  /**
   * Send SEEN event to backend on question change
   * I can ignore thew result/status of this call
   */
  useEffect(() => {
    if (questionId) {
      pollQuestionSeen(slugId, poll._id, questionId)
        .then(() => {})
        .catch(() => {});
    }
  }, [questionId, poll._id, slugId]);

  /**
   *
   */
  async function onSkipQuestion() {
    await pollQuestionSkip(slugId, poll?._id, questionId);
    onEndQuestion();
  }

  /**
   * calculate the amount of points earned per question answered
   */
  function getPoints() {
    return SurveyUtils.getPoints(surveyList, answers.current, poll?._id);
  }

  /**
   *
   */
  function isConditionalLogicQuestion() {
    const questionRules = questions[currentQuestionIndex]?.questionConfiguration?.conditionalExecution?.rules;
    return (questionRules && questionRules.length > 0) || false;
  }

  /**
   *
   */
  function getCurrentQuestion(): CommonQuestionProps {
    // console.log('CurrentQuestionIndex:', currentQuestionIndex, poll?.questions[currentQuestionIndex]);
    return questions[currentQuestionIndex];
  }

  /**
   * Callback from question when submitting an answer
   * This is the first thing called by executeUpdate
   * aka showCoinsInHeader in old implementation
   */
  function executeUpdateBegins(isQQ: boolean, answer: Answer): boolean {
    // console.log('ShowCoins:', showCoins, isQQ, answer);
    answers.current = [...answers.current, answer];
    setHideSkipAfterAnswer(true);

    // SEND gtag event
    const currentPoll = surveyList.polls.find((item) => item._id === poll._id);
    if (!currentPoll?.completedQuestions && currentQuestionIndex === 0) {
      window.gtag('event', 'firstSubmit', { event_category: 'polls', event_label: poll._id });
    }

    let showCoinsIfCharity = false;
    const activeCharities = SurveyUtils.getActiveCharities(charities);
    if (activeCharities.length > 0 && !isQQ) {
      setShowCoins(true);
      // we don't wait for coins hiding
      // Show coins for question for SUBMIT_DELAY
      // then show total coins earned for another SUBMIT_DELAY
      setTimeout(() => {
        setShowCoins(false);
      }, 2 * SUBMIT_DELAY);
      showCoinsIfCharity = true;
    }
    return showCoinsIfCharity;
  }

  const modalClassModifier = SurveyUtils.getModalClassNameModifier(currentForm, annotateEdit, isMediaZoomed);

  /**
   * Pre-packaging props for child components to avoid messy markup
   */
  // Progress bar props
  const pollProgressProps = {
    questionCount: SurveyUtils.getProgressQuestionsTotal(poll),
    currentStep: SurveyUtils.getCurrentStepNumber(poll, currentQuestionIndex),
  };

  // Question header props
  const questionHeaderProps = {
    currentQuestion: SurveyUtils.getCurrentQuestionNumber(poll, currentQuestionIndex),
    questionCount: SurveyUtils.getTotalNrQuestions(questions),
    onSkipQuestion,
    onClosePoll: closePoll,
    isQuestion: isQuestion(currentForm, INCLUDE_QQ, EXCLUDE_FACTS, INCLUDE_DEMOGRAPHIC),
    isQualifyingQuestion: isQualifyingQuestion(currentForm),
    isDemographicQuestion: isDemographicQuestion(currentForm),
    points: getPoints(),
    showCoins,
    hideSkipDemographic: hideSkipDemographic || hideSkipAfterAnswer || isConditionalLogicQuestion(),
    isConditionalPoll: poll?.isConditionalPoll,
    hideClosePollButton: projectConfiguration?.customizations.hideClosePollButton,
  };

  // Question params
  const questionParams = {
    // Need key to trigger re-render on question change if repeated question type
    key: getCurrentQuestion()?._id || 0,
    question: getCurrentQuestion(),
    slugId,
    pollId: poll?._id,
    getUpdatedPolls: () => {}, // Not used in new implementation - we don't reload survey after every question
    questionButtonClicked: onEndQuestion,
    showCoin: showCoins,
    // QQ specific
    // qType is needed because we re-use ImageQualifyingQuestions component for 3 question types
    qType: currentForm,
    isQQ: isQualifyingQuestion(currentForm),
    showCoinsInHeader: executeUpdateBegins,
    setHideSkipDemographic,
    skipResults: poll?.skipCommunityResponse,
    setAnnotateEdit,
    setHideSkipAfterAnswer,
    setIsMediaZoomed,
    isMediaZoomed,
  };
  /**
   * End of pre-packaging props
   */

  function formSwitcher() {
    // Render questions
    if (isQuestion(currentForm, INCLUDE_QQ, INCLUDE_FACTS, INCLUDE_DEMOGRAPHIC)) {
      const questionFormType = QuestionHelper.questionSwitcher(currentForm, questionParams) as JSX.Element | null;
      if (questionFormType) {
        return questionFormType;
      }
      getNextQuestion();
    }

    /**
     * Handling remaining non-question actions
     */
    switch (currentForm) {
      case AT_1007_CHARITY:
        return <EndOfPollCharity slugId={slugId} closePoll={closePoll} onSubmitCharity={onCharityClick} />;

      case AT_1014_END_OF_POLL:
        return (
          <EndOfPollAnonymous
            customizationsNew={projectConfiguration?.customizations}
            pollId={poll._id}
            nextPollAction={nextPoll}
            closePoll={closePoll}
            slugId={slugId}
            pollsNew={surveyList}
          />
        );

      default:
        return <EndOfPollAnonymous pollId={poll._id} nextPollAction={nextPoll} closePoll={closePoll} slugId={slugId} />;
    }
  }

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        <div className='c-modal-poll-container'>
          <div className={`c-modal-poll ${modalClassModifier}`}>
            {SurveyUtils.doShowPollHeader(currentForm) && (
              <>
                <QuestionHeader {...questionHeaderProps} />
                <PollProgress {...pollProgressProps} />
              </>
            )}
            <div className='c-modal-poll__content'>{getCurrentQuestion() !== null ? formSwitcher() : null}</div>
          </div>
        </div>
      </div>
    </main>
  );
}
