/**
 * NEW SURVEY PAGE (no popup) API SERVICE
 */
import type { ApiDataForSurvey, AboutType, SurveyListType, CharityType } from 'types/apiTypes';
import type { Polls } from 'components/organisms/question/types';

import { getDataFromServer as retrieveProjectAbout } from './project/header';
import { retrievePollFromServer, getPollsFromServer } from './polls';
import { getretrieveCharities as getCharities } from './project/retrieveCharities';

/**
 * Retrieves all necessary data for the new Survey page in parallel (4 separate API requests).
 * I expect to optimize further to provide a single API endpoint that returns all necessary data once the spec is stable.
 * @param slugId
 * @param pollId
 * @returns
 */
export async function retrieveDataForSurvey(slugId: string, pollId: string): Promise<ApiDataForSurvey> {
  const apiRequests = [
    retrieveProjectAbout(slugId),
    retrievePollFromServer(slugId, pollId),
    getPollsFromServer(slugId),
    getCharities(slugId),
  ];

  const apiRes = await Promise.all(apiRequests);

  const res = {
    about: apiRes[0].data as AboutType,
    poll: apiRes[1].data as Polls,
    surveyList: apiRes[2].data as SurveyListType,
    charities: apiRes[3].data as CharityType[],
  };

  return res;
}
