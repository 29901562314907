import React, { useState, useRef, useEffect } from 'react';

import SvgIcon from '../../../../SvgIcon';
import i18n from '../../../../../i18n/helper';

export default function DemographicTemplateChoiceOther(props) {
  const { choiceKey, choiceLabel, questionType, handleSelect, choiceId } = props;

  const [feedback, setFeedback] = useState('');
  const [otherVisible, setOtherVisible] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (otherVisible) {
      inputRef.current.focus();
    }
  }, [otherVisible]);

  const handleSelectAndHideOther = (event, choiceId_, feedback_) => {
    event.preventDefault();
    setOtherVisible(false);
    handleSelect(choiceId_, feedback_);
  };

  return (
    <div className={`c-choice-list c-choice-list--other ${feedback ? 'is-filled' : 'is-empty'}`}>
      <input
        type='checkbox'
        id={`${choiceKey}_${choiceId}`}
        name={questionType}
        onChange={() => setOtherVisible(true)}
      />
      <label htmlFor={`${choiceKey}_${choiceId}`}>
        <span className='c-choice-list__title'>{choiceLabel}</span>
        <SvgIcon icon='pen' />
        <SvgIcon icon='check' />
      </label>
      {otherVisible && (
        <div className='c-choice-list__other'>
          <label htmlFor={`${choiceKey}_${choiceId}-other`}>{i18n.t('demographic.question.other')}</label>
          <input
            ref={inputRef}
            type='text'
            placeholder={i18n.t('demographic.question.other')}
            id={`${choiceKey}_${choiceId}-other`}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            maxLength={30}
          />
          <button className='c-button' onClick={(e) => handleSelectAndHideOther(e, choiceId, feedback)}>
            <span className='o-label'>{i18n.t('general.OK')}</span>
          </button>
        </div>
      )}
    </div>
  );
}
