import { Question, FormTypes } from 'components/organisms/questions/types';
import QuestionHelper from 'containers/questions/QuestionHelper';
import type { CharityType, Customizations } from 'types/apiTypes';
import {
  getCombinedQuestions as _getCombinedQuestions,
  getTotalNrQuestions as _getTotalNrQuestions,
} from 'utils/pollQuestionUtils';
import { SurveyListType } from 'types/apiTypes';
import { getQueries } from '../../components/link/LinkHelper';
import { history } from '../../store';

import {
  makeQualifyingQuestion,
  isAnnotateImageQuestion,
  isAnnotateMapQuestion,
  isPersonalDataQuestion,
  isDemographicQuestion,
} from '../questions/QuestionConstants';
import type { Answer, Polls } from '../../components/organisms/question/types';

const IMAGE_ZOOM_CLASS = 'pan-zoom-modal-active';

/**
 *
 */
function getTrimmedPath(location: Location): string {
  let trimmedPath = '';
  if (location.pathname) {
    const array = location.pathname.split('/');
    trimmedPath = `/${array[0]}${array[1]}/${array[2]}`;
  }
  return trimmedPath;
}

/**
 *
 */
function closePoll(customizations: Customizations | null, location: Location): void {
  if (customizations?.hideClosePollButton) {
    return;
  }
  const targetURL: string = `${getTrimmedPath(location)}${getQueries()}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  history.push(targetURL);
}

/**
 *
 */
function gotoNextSurvey(nextSurveryId: string, location: Location): void {
  const nextSurveyPath = `${getTrimmedPath(location)}/${nextSurveryId}${getQueries()}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  history.push(nextSurveyPath);
}

/**
 *
 */
// Bridge to use the same logic as in old implementation (via Redux state reducers/poll.js)
function getCombinedQuestions(poll: Polls | null): Question[] {
  if (!poll) {
    return [];
  }
  const qualifyingQuestion = poll.segmentationQuestion && {
    ...poll.segmentationQuestion,
    question: {
      ...poll.segmentationQuestion?.question,
      questionType: makeQualifyingQuestion(poll.segmentationQuestion?.question.questionType),
    },
  };
  return _getCombinedQuestions(
    poll?.questions || [],
    // IMPORTANT define type for PersonalData
    // [qualifyingQuestion, poll.demographicQuestion, poll.personalData],
    [qualifyingQuestion, poll.demographicQuestion],
    poll?.isConditionalPoll,
  ) as Question[];
}

/**
 *
 */
function getTotalNrQuestions(questions: Question[]): number {
  return _getTotalNrQuestions(questions) as number;
}

/**
 *
 */
function getQuestionSpecificModalClassName(currentForm: FormTypes): string {
  if (isAnnotateImageQuestion(currentForm) || isAnnotateMapQuestion(currentForm))
    return 'c-modal-poll--annotate c-modal-poll--annotate-advanced';
  if (isPersonalDataQuestion(currentForm)) {
    return 'c-modal-poll--pdc-form';
  }
  return '';
}

/**
 *
 */
function getModalClassNameModifier(currentForm: FormTypes, annotateEdit: string, isMediaZoomed: boolean): string {
  const demographicsClass = isDemographicQuestion(currentForm) ? 'c-modal-poll--demographic' : '';
  const questionSpecificClass = getQuestionSpecificModalClassName(currentForm);
  const annotateEditClass = annotateEdit;
  const mediaZoomClass = isMediaZoomed ? IMAGE_ZOOM_CLASS : '';
  return `${demographicsClass} ${questionSpecificClass} ${annotateEditClass} ${mediaZoomClass}`;
}

/**
 *
 */
function getPoints(
  surveyList: SurveyListType,
  answers: Answer[],
  surveyId: string,
): { pointsPerQuestion: number; pointsEarned: number } {
  const currentPoll = surveyList.polls.find((item) => item._id === surveyId);
  const pointsPerQuestion = currentPoll ? currentPoll.coins / currentPoll.questionCount : 0;
  const pointsEarned = currentPoll ? (currentPoll.completedQuestions + answers.length) * pointsPerQuestion : 0;
  const points = {
    pointsPerQuestion,
    pointsEarned,
  };
  return points;
}

/**
 *
 */
function getActiveCharities(charities: CharityType[]): CharityType[] {
  if (!charities) {
    return [];
  }
  return charities.filter((charity) => charity.isActive);
}

/**
 *
 */
function doShowPollHeader(currentForm: FormTypes): boolean {
  return QuestionHelper.doShowPollHeader(currentForm) as boolean;
}

/**
 *
 */
function getCurrentQuestionNumber(poll: Polls, currentQuestionIndex: number): number {
  return QuestionHelper.getCurrentQuestionNumber(poll, currentQuestionIndex);
}

/**
 *
 */
function getProgressQuestionsTotal(poll: Polls): number {
  return QuestionHelper.getProgressQuestionsTotal(poll) as number;
}

/**
 *
 */
function getCurrentStepNumber(poll: Polls, currentQuestionIndex: number): number {
  return QuestionHelper.getCurrentStepNumber(poll, currentQuestionIndex);
}

/**
 *
 */
function announcePageLoading(message: string) {
  // Create a hidden live region
  const liveRegion = document.createElement('div');
  liveRegion.setAttribute('aria-live', 'assertive'); // Announce immediately
  liveRegion.setAttribute('role', 'alert'); // Screen reader prioritizes this announcement
  liveRegion.style.position = 'absolute';
  liveRegion.style.left = '-9999px'; // Hide visually but keep it in the DOM
  liveRegion.textContent = message; // Message to screen reader

  // Append the live region to the body
  document.body.appendChild(liveRegion);

  // Clean up the live region after a short delay
  setTimeout(() => {
    document.body.removeChild(liveRegion);
  }, 3000);
}

export default {
  announcePageLoading,
  closePoll,
  getCombinedQuestions,
  getTotalNrQuestions,
  getModalClassNameModifier,
  getActiveCharities,
  doShowPollHeader,
  getCurrentQuestionNumber,
  getProgressQuestionsTotal,
  getCurrentStepNumber,
  getPoints,
  gotoNextSurvey,
};
