import React from 'react';
import PropTypes from 'prop-types';
import { getTokenHash } from 'utils/utils';
import BackgroundImage from 'assets-v2/images/powered-by-gmv@4x.png';
import BackgroundImageAlt from 'assets-v2/images/powered-by-gmv-alternative@4x.png';
import { POWERED_BY_LINK, FEATURE_FLAG_POWERED_BY } from '../../constants';
import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';

const QuestionHeader = (props) => {
  const {
    onClosePoll,
    questionCount,
    currentQuestion,
    isQuestion,
    isQualifyingQuestion,
    isDemographicQuestion,
    onSkipQuestion,
    points,
    showCoins,
    hideSkipDemographic,
    isConditionalPoll,
    hideClosePollButton,
  } = props;

  const getHeaderTitle = () => {
    if (isDemographicQuestion) {
      return i18n.t('demographic.title');
    }
    if (isConditionalPoll) {
      return '';
    }
    if (isQuestion) {
      return `${i18n.t('questions.question')}   ${currentQuestion} / ${questionCount}`;
    }
    return i18n.t('questions.quickfact');
  };

  const getAlternativeImageABTest = () => {
    const isAlternative = Math.abs(getTokenHash()) % 2;
    return isAlternative ? { bg: BackgroundImageAlt, type: 'a' } : { bg: BackgroundImage, type: 'g' };
  };

  return (
    <React.Fragment>
      <div className='c-modal-poll__header'>
        {!hideClosePollButton && (
          <button className='c-button c-modal-poll__close' onClick={onClosePoll}>
            <span className='c-button__label'>{i18n.t('close')}</span>
            <SvgIcon icon='times' />
          </button>
        )}
        {FEATURE_FLAG_POWERED_BY ? (
          <div className='c-modal-poll__powered-by' aria-hidden>
            <a
              href={`${POWERED_BY_LINK}?type=${getAlternativeImageABTest().type}`}
              className='powered-by-cta'
              rel='noopener noreferrer'
              target='_blank'
              title='This survey is powered by Give My View'
              style={{ background: `url(${getAlternativeImageABTest().bg})`, backgroundSize: '6.875rem 1.75rem' }}
              tabIndex='-1'
            >
              <span>Powered by Give My View</span>
            </a>
          </div>
        ) : (
          <h3 className={`c-modal-poll__label ${isQuestion ? '' : 'u-screen-reader-text'}`} id='modal-poll-title'>
            {getHeaderTitle()}
          </h3>
        )}
        <h3 className='c-points-label' style={showCoins ? { display: 'flex' } : { display: 'none' }}>
          <span className='c-points-label__earned'>
            {`+${points.pointsPerQuestion} ${i18n.t('questions.pointsEarned')}`}
          </span>
          <span className='c-points-label__total'>{`${points.pointsEarned} ${i18n.t('questions.pointsTotal')}`}</span>
        </h3>
        {!isQualifyingQuestion && (
          <button
            className='c-button c-modal-poll__skip'
            onClick={(event) => {
              onSkipQuestion(event, currentQuestion);
            }}
            disabled={hideSkipDemographic}
            style={{ opacity: hideSkipDemographic ? 0 : 1 }}
          >
            <span className='c-button__label'>{i18n.t('header.skip')}</span>
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default QuestionHeader;

QuestionHeader.propTypes = {
  onClosePoll: PropTypes.func.isRequired,
  questionCount: PropTypes.number.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  isQuestion: PropTypes.bool,
  isQualifyingQuestion: PropTypes.bool,
  isDemographicQuestion: PropTypes.bool,
  onSkipQuestion: PropTypes.func,
  hideSkipDemographic: PropTypes.bool,
  points: PropTypes.object.isRequired,
  showCoins: PropTypes.bool,
  isConditionalPoll: PropTypes.bool,
  hideClosePollButton: PropTypes.bool,
};
